@import '../../common.scss';

.pg-home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  &__header {
    height: px2rem(42px);
    line-height: px2rem(42px);
    text-align: center;
    border-bottom: solid px2rem(1px) #eee;
    font-size: px2rem(18px);
    font-weight: bold;
  }
  &__talk {
    flex: 1;
    overflow-y: auto;
    &-item {
      display: flex;
      width: 100%;
      font-size: px2rem(14px);
      padding: px2rem(10px);
      box-sizing: border-box;
      margin-bottom: px2rem(10px);
      &-content {
        position: relative;
        flex: 1;
        margin-left: px2rem(10px);
        text-align: left;
        box-shadow: 0 0 px2rem(20px) 0 rgba(51,51,51,.1);
        padding: px2rem(10px);
        background-color: aquamarine;
        border-radius: px2rem(10px);
        overflow-x: auto;
        pre {
          background-color: gray;
          color: white;
          padding: px2rem(10px);
          border-radius: px2rem(10px);
          display: block;
          width: 100%;
          overflow-x: auto;
          box-sizing: border-box;
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border: px2rem(10px) solid;
          border-color: transparent transparent aquamarine;
          transform: rotateZ(-90deg);
          left: px2rem(-20px);
          top: px2rem(10px);
        }
      }
      &.user {
        flex-direction: row-reverse;
      }
      &.user &-content {
        margin-left: 0;
        margin-right: px2rem(10px);
        background-color: white;
        overflow-wrap: break-word;
        overflow: hidden;
        &::after {
          border-color: transparent transparent white;
          transform: rotateZ(-270deg);
          left: unset;
          right: px2rem(-20px);
          top: px2rem(10px);
        }
      }
    }
  }
  &__input {
    border-top: solid px2rem(1px) rgba($color: #000000, $alpha: .1);
    width: 100%;
    padding: px2rem(10px) px2rem(5px);
    box-sizing: border-box;
    font-size: px2rem(14px);
  }
}